<template>
  <div class="company-page">
    <headerBar :title="i18n.t('company.title')"  :showArrow="true" @back="back"></headerBar>

    <div class="content">
      <div class="media">
        <video controls autoplay>
          <source src="https://www.98472y.top/uploads/20230408/c23289e0d6860f8b42c93207a8c9c23a.mp4" type="video/mp4">
        </video>
      </div>
      <template v-if="pop_window === 'true'">
        {{text}}
      </template>
      <template v-else>
        <p>{{ i18n.t('company.content_1') }}</p>
        <p>{{ i18n.t('company.content_2') }}</p>
        <p>{{ i18n.t('company.content_3') }}</p>
      </template>

    </div>
  </div>
</template>
<script>
import { Button,  } from 'vant'
import headerBar from '@/components/header'
import { getArticleList } from '@/api/index'
import moment from 'moment'
export default {
  components: {
    headerBar,
    Button,
  },
  computed: {
    appName() {
      return this.$store.state.appName
    },
    logo() {
      return this.$store.state.logo
    },
    pop_window(){
      return this.$store.state.pop_window
    }
  },
  data() {
    return {
      contentObj: {},
      text: ""
    }
  },
  mounted() {
    this.text = this.i18n.t("index.desc1")
    let ensure = this.i18n.t("common.ensure")
    this.text = this.text.replaceAll("$1", this.$store.state.appName);
    this.text = this.text.replaceAll("$2", "\n");
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    init() {
      getArticleList().then(res => {
        let list = res.data.Items
        let data = list.filter(v => {
          return v.Type == 1
        })
        if (data.length > 0) {
          this.contentObj = data[0]
        }
      })
    },

  }
}
</script>